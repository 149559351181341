.blog {
  &__container {
    padding: 180px 0 117px;
    background: #D3E1E1;
  }

  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 48px;
    line-height: 100%;
    color: #232323;
    margin-bottom: 40px;
    font-family: 'Roboto', sans-serif;
  }

  &__desc {
    text-align: center;
    // width: 56%;
    font-weight: 500;
    font-size: 21px;
    line-height: 160%;
    margin-bottom: 120px;
  }

  &__row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0 -16px;
  
  }

  &__item {
    width: 33%;
    margin: 0 16px;
    color: #333333;

    &:hover {
      color: #333333
    }
  }

  &__img {
    width: 100%;
    height: 368px;
    // background: center/cover no-repeat url(../../images/blog-img-1.png);
    margin-bottom: 31px;
  }

  &__text {
    padding: 0 36px 41px 36px;
    font-weight: 500;
    font-size: 25px;
    line-height: 130%;

    span {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      opacity: 0.5;
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 991.98px) {
  .blog__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 28px;

  }

  .blog__container {
    overflow: hidden;
    padding: 84px 0 98px 36px;
  }

  .blog__row {
    overflow-x: scroll;
    margin: 0 -10px;
  }

  .blog__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;


    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 8px;
    }

    padding-left: 26px;
  }

  .blog .wrapper {
    padding-right: 0;
  }

  .blog__item {
    min-width: 318px;
    margin: 0 10px;
  }

  .blog__img {
    height: 240px;
  }

  .blog__desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 80%;
    margin-bottom: 52px;
  }
}

@media (max-width: 767.98px) {}

@media (max-width: 575.98px) {
  .blog__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
  }

  .blog__container {
    padding: 55px 16px 67px 16px;
  }

  .blog__desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    width: 100%;
    margin-bottom: 62px;
  }
}