.accordion {
    // padding-bottom: 150px;

    &__box {
        display: flex;

        &:first-of-type {
            .accordion-item {
                padding-top: 13px;

            }

            .accordion__number {
                padding-top: 0;
            }
        }

        &:last-of-type {

            .accordion__number,
            .accordion-item {
                border-bottom: none;
            }
        }
    }

    &__number {
        text-align: center;
        padding: 22px 0;
        font-weight: 400;
        font-size: 60px;
        line-height: 76px;
        min-width: 99px;
        border-right: 2px solid var(--accent-color);
        border-bottom: 2px solid var(--accent-color);
    }
}
.accordion__box:first-of-type .accordion-item{
    border-top: none;
}
// .accordion-item:first-of-type,
.accordion-item:last-of-type .accordion-button.collapsed,
.accordion-button:focus {
    border: none;
    box-shadow: none;
}

.accordion-item,
.accordion-item:last-of-type,
.accordion-item:last-of-type .accordion-collapse {
    border-radius: 0;
    border-right: none;
    width: 100%;
}

.accordion-item {
    &::after {}

    border-bottom: 2px solid var(--accent-color);
    border-radius: 0;
}

.accordion-button,
.accordion-button:not(.collapsed) {
    background-color: transparent;
    // font-family: 'DM Sans', sans-serif;
    border: none;
    box-shadow: none;
    font-weight: 400;
    font-size: 36px;
    line-height: 50px;
    color: var(--black-color);
    padding: 0;
}

.accordion-body {
    padding: 37px 0 12px;
    width: 90%;
}

.accordion-item {
    padding: 35px 0px 36px 35px;
}

.accordion-button::after {
    width: 40px;
    height: 40px;
    background-image: url(../../images/Open.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(../../images/Close.svg);
}

@media (max-width: 768.98px) {
    .accordion__number {
        font-weight: 400;
        font-size: 36px;
        line-height: 76px;
        min-width: 53px;
        padding: 0;
    }

    .accordion-item {
        padding: 9px 0px 13px 13px;
    }
    .accordion__box:first-of-type .accordion__number,
    .accordion__number {
padding: 13px 0;
        align-items: center;
        padding-right: 5px;
      }
    .accordion-button,
    .accordion-button:not(.collapsed) {
        font-weight: 400;
        font-size: 20px;
        line-height: 48px;
    }

    .accordion-button::after {
        width: 24px;
        height: 24px;
    }

    .accordion-body {
        font-weight: 400;
        padding: 9px 0 2px;
        font-size: 14px;
        line-height: 24px;
    }

    .accordion {
        max-width: 656px;
        width: 100%;
        margin: 0 auto;
    }
}

@media (max-width: 575.98px) {

    .accordion-button,
    .accordion-button:not(.collapsed) {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .accordion-item {
        padding: 13px 0px 10px 8px;
    }
    // .accordion__box
    .accordion__number {
        font-size: 24px;
        min-width: 24px;
        line-height: 32px;
    }

    .accordion-body {
        font-weight: 400;
        padding: 14px 0 57px;
        font-size: 10px;
        line-height: 140%;
    }
}