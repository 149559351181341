@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&family=DM+Sans:wght@400;500;700&family=Roboto:wght@400;700&display=swap");
@import url("../styles/normalize.css");

@import url("../styles/styles.scss");

html[data-increase="true"] {
  .header__nav {
    max-width: 700px !important ;
  }
  // .header__inner {
  //   max-width: 1250px;
  // }
  // .header__wrapper {
  //   max-width: 100%;
  // }
  .preview__title span {
    font-weight: 400;
    font-size: 24px;
  }
  * {
    font-size: 18px !important;
    line-height: 25px !important;
    letter-spacing: 0 !important;
  }
  .preview__title {
    width: auto;
  }
}

html[data-color="white"] {
  .header__container,
  .header__inner {
    z-index: auto;
  }
  .button-primary:active,
  .button-primary:focus,
  .button-primary:hover {
  }
  .description .title {
    border: 1px solid;
  }
  .header,
  .header__box,
  .header__wrapper,
  .header__bg {
    z-index: 9;
    background-color: #ffffff00 !important;
  }
  &:not(.header),
  &:not(.header__bg) {
    background-color: #ffffff00 !important;
  }
  * {
    color: black !important;
    background-color: #fff !important;
    border-color: rgb(3, 3, 3) !important;
  }
  // .footer__container ,
  // .footer .box-content {
  //   background-color: #191919 !important;
  //   color: #f7f3d6 !important;
  // }
  .button-primary,
  .feedback__textarea,
  .feedback__input {
    border: 1px solid;
  }
}

html[data-color="black"] {
  .swiper-button {
    background-color: transparent !important;
  }
  .header__container,
  .header__inner {
    z-index: auto;
  }
  .description .title {
    border: 1px solid;
  }
  .disabled-menu.active {
    background-color: #ffffff00 !important;
  }
  .header,
  .header__box,
  .header__wrapper,
  .header__bg {
    background-color: #ffffff00 !important;
    z-index: 9;
  }
  &:not(.header),
  &:not(.header__bg) {
    background-color: #ffffff00 !important;
  }
  * {
    color: #fff !important;
    background-color: black !important;
    border-color: #fff !important;
  }
}
html[data-color="yellow"] {
  .header__container,
  .header__inner {
    z-index: auto;
  }
  .description .title {
    border: 1px solid;
  }
  .header,
  .header__box,
  .header__wrapper,
  .header__bg {
    z-index: 9;
    background-color: #ffffff00 !important;
  }
  .button-primary,
  .feedback__textarea,
  .feedback__input {
    border: 1px solid;
  }
  * {
    color: #000000 !important;
    background-color: #f7f3d6 !important;
    border-color: rgb(3, 3, 3) !important;
  }
}

html[data-img="hidden"] {
  .header__logo,
  .detail__img,
  .preview__bg {
    background-image: none !important;
  }
  .social,
  .footer__logo,
  .blog__img,
  .swiper-button,
  .reviews__img,
  .about__additional::after,
  .about__additional::before,
  .about__icon,
  .accordion-button::after,
  .problem-solving__gallery,
  .about__scroll,
  .swwww .tt,
  .news__img,
  .news-page__img,
  .header__phone,
  .logo,
  .representatives__item,
  .preview-about__item,
  .preview__img {
    display: none;
  }
  .representatives__item {
    border: 1px solid;
  }

  .contact__link::before,
  .languages .languages__link::before,
  * {
    // background-image: none !important;
    // background-color: #f7f7f700 !important;
  }
  .header__disabled-icon {
    background: center/cover no-repeat url(../images/disabled-icon.svg) !important;
  }
}
// .swiper-button {
//   .button {
//     font-weight: 400;
//     font-size: 15px;
//     line-height: 22px;
//     color: #a1a1a1;
//   }

//   position: absolute;
//   bottom: -9px;
//   z-index: 9;
//   max-width: 544px;
//   width: 100%;
//   margin-right: auto;
//   right: 0;
//   display: flex;
//   justify-content: space-between;
// }

// html[data-img="inverse"]
html[data-img="hidden"] .contact__box::before {
  content: none;
}
html[data-img="inverse"] {
  .location-card__link,
  .location-card__name,
  .location-card__desc,
  .location-card__title {
    // color: #ffffff !important;
  }
  .trailer-page {
    .header__bg {
      background-color: transparent !important;
      z-index: 1;
    }
  }
  .swwww .tt,
  .preview__bg,
  .preview__img,
  .about__icon ,
  .problem-solving__item,
  .detail__img,
  .reviews__img,
  .blog__img,
  .footer__logo,
  .blog-page__bg,
  .preview__img img,
  .preview-about__item,
  .representatives__item,.news__img,
  .questions__img,.meta__img,
  .news-page__img,
  .biography-desc__img,.biography-socail__link,
  .header__bg,
  .trailer-plan__img,
  .gallery-thumbs-small__item,
  .gallery-thumbs__item,
  .contact__link::before,
  .footer__item,
  .reviews__scroll,
  .reviews__avatar,
  .location-card__img,
  .contact__box::before,
  .header__logo,
  .header__phone,
  // .disabled-menu,
  .header-social__link,
  .header__disabled-icon,
  .preview-slider__box,
  .help-item__icon,
  .card__img {
    filter: invert(1);
  }
}
html .disabled-menu__color_white {
  background-color: #fff !important;
}
html .disabled-menu__color_black {
  background-color: #000000 !important;
}
html .disabled-menu__color_yellow {
  background-color: #f7f3d6 !important;
}
body {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  background-color: var(--main-color);
}

.wrapper {
  max-width: 1888px;
  width: auto;
  margin: 0 auto;
  padding: 0 16px;
}

.wrapper-box {
  max-width: 1312px;
  width: 100%;
  margin: 0 auto;
}

.button-dark {
  border: none;

  cursor: pointer;
  font-family: "Roboto", sans-serif;
  background-color: var(--black-color);
  color: var(--main-color);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 12px;
  border-radius: 32px;

  &:hover {
    color: var(--main-color);
  }
}

.button-transparent {
  cursor: pointer;

  &:hover {
    color: var(--black-color);
  }

  background-color: transparent;
  color: var(--black-color);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border: 1px solid var(--black-color);
  padding: 12px;
  border-radius: 32px;
}

h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
  color: #232323;
}

.scroll-hidden {
  overflow-y: hidden;
}

@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
}

@media (max-width: 768.98px) {
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
  }
}

@media (max-width: 575.98px) {
  h2 {
    font-size: 20px;
    line-height: 100%;
  }

  h1 {
    font-size: 20px;
    line-height: 100%;
  }
}

.app.he {
  p {
    font-family: Arimo !important;
  }
  .about__title {
    font-family: Arimo;
  }
  .button-dark {
    font-family: Arimo;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .about__flex {
    flex-direction: row-reverse;
  }
  .about__desc {
    margin-right: 0;
    margin-left: 22px;
  }
  .about__title {
    margin-left: auto;
    text-align: right;
  }
  .swiper-preview .slider-swiper {
    background-position-x: 80% !important ;
  }
  .slider-swiper__text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .about__item:last-of-type {
    margin-right: 10px;
  }
  .blog-page__container {
    flex-direction: row-reverse;
  }
  .header__link {
    margin-left: auto;
  }
  .services__text span {
    margin-right: 5px;
  }
  .accordion-button {
    text-align: right;
  }
  font-family: "Arimo", sans-serif;

  .header__language {
    flex-direction: row-reverse;
  }
  .detail__price {
    span {
      transform: scale(-1, 1);
    }
    flex-direction: row-reverse;
  }
  .preview__position,
  .header {
    font-family: "Arimo", sans-serif;
  }

  .form__desc {
    width: 100%;
  }

  .form__input,
  .form__title,
  .form__desc,
  .about__desc {
    text-align: right;
  }

  .services__text {
    display: flex;
    span {
      transform: scale(-1, 1);
    }

    align-items: center;
  }

  .mobile-menu .header__row {
    display: flex;
    flex-direction: column-reverse;
  }

  .header__link {
    // margin-left: auto;
  }

  .mobile-menu .header__nav {
    text-align: right;
  }

  .mobile-menu .header__logo {
    margin-left: auto;
    margin-right: 0;
  }

  .header__row,
  .footer__flex,
  // .footer__box,
  .footer__row,
  .form__container,
  .services__box,
  .about__row,
  .header__box,
  .header__container {
    flex-direction: row-reverse;
  }

  .header__nav {
    flex-direction: row-reverse;
    max-width: 600px;
  }

  .accordion__box {
    flex-direction: row-reverse;
  }

  .accordion-item {
    border-left: none;
    padding: 35px 35px 36px 0px;
  }

  .accordion-body {
    margin-left: auto;
    text-align: right;
    color: #707070;
  }

  .accordion__number {
    // transform: scaleX(-1);
    border-right: none;
    border-left: 2px solid var(--accent-color);
  }

  .accordion-button,
  .accordion-button:not(.collapsed) {
    display: flex;
    // flex-direction: row-reverse;
    justify-content: space-between;

    &::after {
      margin: 0;
    }
  }
  .accordion-button::after {
    margin-right: 5px;
  }
  .contacts__desc,
  .blog__text
  // .blog__desc,
  // .blog__title
  {
    width: 100%;
    text-align: right;
  }
}

@media (max-width: 768.98px) {
  .app.he .mobile-menu .header__logo {
    margin-left: 0;
    margin-right: auto;
  }

  // .app.he .accordion-item {
  //   border-left: none;
  //   padding: 15px 15px 15px 0px;
  // }
  .app.he {
    .button-dark {
      font-size: 16px !important;
      font-family: Arimo !important;
      font-weight: 700;
      line-height: 22px !important;
      letter-spacing: 1px;
    }
    .accordion-item {
      border-left: none;
      padding: 9px 13px 13px 9px;
    }
    .accordion__number {
      // display: flex;
      padding-left: 5px;
    }
    .blog-page {
      text-align: right;

      .blog-page__container {
        flex-direction: row-reverse;
      }
    }

    .header__container {
      flex-direction: row;
    }
  }
}
