.form {
    .about__additional {
        max-width: 851px;
        width: 100%;
    }

    &__box {
        background: #D7B56D;

    }

    &__container {
        padding: 143px 0 100px;
        display: flex;
        justify-content: space-between;
        margin: 0 -5px;
    }

    &__title {
        margin-bottom: 40px;
    }

    &__desc {
        width: 90%;
    }

    &__column {
        display: block;
        max-width: 576px;
        width: 100%;

    }

    &__input {
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        border-radius: 36px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        padding: 12px 15px 10px;
        margin-bottom: 16px;

        &:nth-child(3) {
            margin-bottom: 32px;
        }
    }

    &__button {
        width: 100%;
        font-weight: 700;
        font-size: 19px;
        line-height: 31px;
    }
}

@media (max-width: 991.98px) {
    .form__container {
        padding: 87px 56px 107px 33px;
    }

    .form__title {
        margin-bottom: 25px;
    }

    .form__desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
    }

    .form__column {
        max-width: 318px;
        position: relative;
        top: 30px;
    }

    .form__input:nth-child(3) {
        margin-bottom: 27px;
    }
}

@media (max-width: 767.98px) {}

@media (max-width: 575.98px) {
    .form__container {
        padding: 56px 16px 70px;
        display: block;
    }

    .form__title {
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
    }

    .form__desc {
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
    }

    .form__column {
        top: 0;
        margin-top: 51px;
        max-width: 100%;
    }
}