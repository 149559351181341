.social {
    width: max-content;

    &__container {
        width: auto;
        display: flex;
        margin: 0 -4px;

        &.light .social__link {
            background: var(--main-color);

            &:before {
                background-position-y: 100%;
            }
        }
    }

    &__link {

        margin: 0 4px;
        min-width: 24px;
        height: 24px;
        // border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: var(--black-color);

        &:before {
            // content: '';
            // display: block;
            // width: 14px;
            // min-width: 14px;
            // height: 15px;
            // background-repeat: no-repeat;
            // background-image: url(../../images/social-icon.svg);
        }

        &_telegram::before {
            background-position-x: 61%;
        }

        &_facebook::before {
            background-position-x: 111%;
        }
    }
}