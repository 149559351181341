.services {
    &__container {
        padding: 152px 0 140px;
    }

    &__title {
        margin-bottom: 120px;
        text-align: center;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        margin: 0 -5px;
    }

    &__item {
        //
        width: 100%;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__box {
        &:first-of-type {
            // padding-top: 0;
        }
        max-width: 600px;
        width: 100%;
        border-bottom: 1px solid #c8952a;
        padding-bottom: 31px;
        padding-top: 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__desc {
        font-weight: 500;
        font-size: 24px;
        line-height: 50px;
    }

    &__text {
        font-size: 48px;
        line-height: 62px;

        span {
            font-size: 32px;
            line-height: 50px;
        }
    }
}

@media (max-width: 991.98px) {
    .services__row {
        max-width: 656px;
        width: 100%;
        margin: 0 auto;
    }

    .services__desc {
        width: 72%;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .services__box {
        padding-top: 5px;
        padding-bottom: 5px;
        border-width: 2px;
    }

    .services__item {
        margin: 0 10px;
    }

    .services__text {
        font-weight: 500;
        font-size: 24px;
        line-height: 62px;

        span {
            font-weight: 500;
            font-size: 20px;
            line-height: 50px;
        }
    }
}

@media (max-width: 768.98px) {
    .services__container {
        padding: 80px 0 88px;
    }

    .services__title {
        margin-bottom: 48px;
    }
}

@media (max-width: 700.98px) {
    .services__row {
        display: flex;
        flex-direction: column;
    }

    .services__item {
        margin: 0;
    }
}

@media (max-width: 575.98px) {
    .services__container {
        padding: 49px 0 88px;
    }

    .services__title {
        margin-bottom: 30px;
    }
}
