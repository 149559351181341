.footer {
  &__container {
    padding: 18px 0 15px;
    background: #191919;
  }

  .header__link {
    color: var(--main-color);

    &:hover {
      color: var(--main-color);

    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px -5px 49px;
  }

  &__copy {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #A1A1A1;
  }

  &__box {
    margin: 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 851px;
    width: 100%;
  }

  &__flex {
    margin: 0 -12px;
    display: flex;
    width: 45%;
  }

  .button-dark {
    background: #F2F2F2;
    color: #191919;

    &:hover {
      color: #191919;
    }
  }

  .button-transparent {
    border-color: #F2F2F2;

    &:hover {
      color: var(--main-color);
    }
  }

  &__button {
    margin: 0 12px;
    color: var(--main-color);
    font-weight: 700;
    max-width: 169px;
    width: 100%;
    display: block;
  }

  &__logo {
    background: center/contain no-repeat url(../../images/logo-footer.png);
    max-width: 356px;
    width: 100%;
    display: block;
    height: 93px;
    mix-blend-mode: color-burn;
  }

  .social {
    margin: 0 5px;
  }
}

@media (max-width: 991.98px) {
  .footer__logo {
    max-width: 214px;
    height: 56px;
  }

  .footer__flex {
    width: 60%;
  }


}

@media (max-width: 768.98px) {

  .footer__box {
    max-width: 656px;
    margin: 0 auto 13px;
  }

  .footer__row {
    margin: 6px -5px 19px;
    flex-direction: column-reverse;
  }

  .footer .header {
    display: none;
  }
}

@media (max-width: 700.98px) {
  .footer__box {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0;
  }

  .footer__flex {
    display: block;
    width: 100%;
  }

  .footer__copy {
    padding-top: 12px;
  }

  .footer__row {
    flex-direction: row-reverse;
    align-items: flex-end;
  }

  .footer__logo {
    margin-bottom: 31px;
  }

  .footer__container {
    padding: 51px 4px 15px;
  }

  .footer__button {
    &:last-of-type {
      margin-bottom: 0;
    }

    margin-bottom: 16px;
  }
}