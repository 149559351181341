.detail {
  font-family: 'Roboto', sans-serif;

  &__container {
    &:last-of-type::after {
      content: none;
    }

    &:nth-child(even) .detail__box {
      flex-direction: row-reverse;
    }

    padding: 0;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      background: center no-repeat url(http://localhost:3000/static/media/Zigzag.d34f5690ae4d29c73143.svg);
      margin: 48px 0;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__img {
    background-image: url(../../../images/image1.png);
    background-size: cover;
    background-position: center;
    width: 50%;
    height: 833px;
  }

  &__info {
    width: 50%;
    margin: 0 auto;

  }

  &__wrapper {
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
  }

  &__name {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
    margin-bottom: 29px;
  }

  &__price {

    span {
      font-size: 16px;
      line-height: 24px;
      color: var(--black-color);
      padding-right: 5px;

    }

    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-weight: 700;
    font-size: 55px;
    line-height: 72px;
    color: var(--accent-color);
    text-align: center;
    margin-bottom: 11px;
  }

  &__days {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #333333;
    opacity: 0.5;
  }

  &__button {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;
    max-width: 280px;
    width: 100%;
    margin: 56px auto 0;
    display: block;
    padding: 17px 5px;
  }

  &__text {
    margin-top: 36px;

    p {
      padding: 12px 5px 10px;
      border-top: 2px solid var(--accent-color);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #333333;

      &:last-of-type {
        border-bottom: 2px solid var(--accent-color);
      }
    }
  }
}

@media (max-width: 991.98px) {
  .detail__img {
    max-width: 318px;
    height: 509px;
    margin-right: 5px;
  }

  .detail__container {
    margin-bottom: 177px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .detail__container::after {
    content: none;
  }
}

@media (max-width: 700.98px) {
  .detail__box {
    display: block;
  }

  .detail__img {
    width: 100%;
    max-width: 100%;
    height: 273px;
    margin-bottom: 50px;
  }

  .detail__text {
    margin-top: 41px;
  }

  .detail__button {
    margin: 38px auto 0;
  }

  .detail__container {
    margin-bottom: 93px;
  }

  .detail__info {
    max-width: 320px;
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .detail__img {
    width: 100%;
    max-width: 320px;
    height: 280px;
  margin: 0 auto 50px;
  }
}