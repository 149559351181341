.header {
    font-family: 'Roboto', sans-serif;
&__phone{
    color: var(--black-color);
    font-size: 20px;
    &:hover{
    color: var(--black-color);

    }
}
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 0 9px;
        margin: 0 -5px;
        border-bottom: 2px solid var(--accent-color);
    }
    &__language{
        display: flex;
        align-items: center;
        max-width: 195px;
        width: 100%;
        justify-content: space-between;
    }
    &__disabled-icon {
        position: fixed;
        right: 15px;
        top: 40%;
        width: 58px;
        height: 58px;
        z-index: 999;
        background: center/cover no-repeat url(../../images/disabled-icon.svg);
    }
    &__box {
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 851px;
        width: 100%;
    }

    .social {
        margin: 0 5px;
    }

    &__logo {
        display: block;
        max-width: 359px;
        width: 100%;
        height: 93px;
        background: center/contain no-repeat url(../../images/header-logo.png);
        position: relative;
        margin-top: -8px;
    }

    &__row {
        display: flex;
        width: max-content;
        align-items: center;
        margin: 0 -12px;
    }

    &__button {
        margin: 0 12px;
        min-width: 169px;
        width: 100%;
    }

    &__nav {
        cursor: pointer;
        padding: 21px 0 27px;
        max-width: 657px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    &__link {
        padding: 8px 10px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        line-height: 120%;
        color: var(--black-color);
        position: relative;

        &:hover,
        &:active,
        &:focus,
        &.active {
            &::after {
                content: '';
                display: block;
                width: 75%;
                position: absolute;
                bottom: 4px;
                height: 1px;
                background: #C8952A;
            }
        }

        &:hover,
        &:active,
        &:focus {
            color: var(--black-color);
        }
    }

    .mobile-button {
        display: none;
    }
}

// @media (max-width: 1399.98px) {}

// @media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {
    .header__logo {
        margin-right: 5px;
    }

}

@media (max-width: 768.98px) {
   
    .header__language,
    .header__button {
        display: none;
    }

    .header__row {
        display: none;
    }

    .header__logo {
        width: 100%;
        height: 56px;
        position: relative;
        top: 4px;
        left: -16%;
        // margin-top: -4px;
    }

    header .header__nav {
        display: none;
    }

    .header__container {
        margin: 0 auto;
        width: 90%;
        padding: 19px 0 19px;
    }

    .header__box {
        max-width: 214.49px;
    }

    .header {

        .mobile-button {
            z-index: 999;
            position: relative;
            display: block;
            width: 24px;
            height: 24px;
            background: no-repeat url(../../images/menu.svg);

            &.close {
                background: no-repeat url(../../images/closeds.svg);
                top: -12px;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .header .social {
        display: none;
    }

    .header__container {
        width: 99%;
    }

    .header__logo {
        left: -4px;
    }
}