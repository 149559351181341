.languages {
  position: relative;
  top: -3px;

  &__box {
    display: none;
    position: absolute;
    bottom: -30px;

    &.active {
      display: block;
    }
  }

  &__btn {
    padding: 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    &_main {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: -15px;
        top: 10px;
        display: block;
        width: 12px;
        height: 6px;
        background: no-repeat url(../../images/Drop-down.svg);
      }

      background: #232323;
    }

    &_second {
      opacity: 0.5;
      background: #232323;
    }
  }
}