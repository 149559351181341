.message{
  position: fixed;
  z-index: -9999;
opacity: 0;
  &.active{
    opacity: 1;
    top: 15px;
    right: 15px;
    background-color: rgb(41, 41, 41);
    padding: 10px 10px;
    border-radius: 5px;
    color: #fff;
    text-transform: uppercase;
    z-index: 9999;
  }
}