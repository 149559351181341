.swiper-preview .slider-swiper {
    width: 100%;
    background-image: url(../../images/flebologiya-nogi-2.jpg);
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    background-position: center !important;
    width: 100%;
    height: 700px;
}
.slider-swiper {
    &__box {
        padding: 199px 0;
        max-width: 1312px;
        width: 100%;
    }
    &__text {
        max-width: 558px;
        width: 100%;
    }
    &__title {
        font-size: 70px;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -1px;
        color: #191919;
        margin-bottom: 40px;
    }
    &__desc {
        color: #000;
        font-size: 20px;
        margin-bottom: 40px;
    }
}
.button-on-slider {
    margin-bottom: 15px;
    padding: 14px 50px;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.swiper-preview {
    // margin-bottom: 30px;
    .tt1 {
        background-image: url(../../images/0427211156431dp.jpg);
        background-size: cover;
        background-position: bottom;
        width: 100%;
        height: 300px;
    }
    .tt2 {
        background-image: url(../../images/7c130368686ab960d35e3beb395c7be3_1601879710_apparatnyjpedikyur2048x13711768x432.jpg);
        background-size: cover;
        background-position: bottom;
        width: 100%;
        height: 300px;
    }
}
.swiper-preview .swiper-button-next {
    background: center/cover no-repeat url(../../images/Right-icon.svg);
    width: 64px;
    min-width: 64px;
    height: 64px;
    &::after {
        content: none;
    }
}
.swiper-preview .swiper-button-prev {
    background: center/cover no-repeat url(../../images/Left-icon.svg);
    width: 64px;
    min-width: 64px;
    height: 64px;
    &::after {
        content: none;
    }
}
.preview {
    font-family: "Roboto", sans-serif;

    &__container {
        padding-bottom: 46px;
    }

    &__bg {
        width: 100%;
        height: 432px;
        background: center/cover no-repeat url(../../images/preview-bg.png);
    }

    &__info {
        width: max-content;
        margin: 0px auto 0;

        &::after {
            content: "";
            display: block;
            width: 2px;
            height: 48px;
            background: var(--accent-color);
            margin: 28px auto 0;
        }
    }

    &__img {
        width: 200px;
        height: 200px;
        background: center/contain no-repeat url(../../images/photo.png);
        border: 2px solid var(--main-color);
        overflow: hidden;
        border-radius: 50%;
        // margin-bottom: 7px;
        margin: 0 auto 7px;
    }

    &__name {
        color: var(--accent-color);
        font-weight: 700;
        font-size: 48px;
        line-height: 100%;
    }

    &__position {
        display: block;
        padding-top: 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: var(--black-color);
        text-align: center;
    }
}
@media (max-width: 1399.98px) {
    .slider-swiper__box {
        padding: 0 50px;
    }
    .swiper-preview {
        .swiper-button-prev,
        .swiper-button-next {
            width: 34px;
            min-width: 34px;
            height: 34px;
        }
    }
    .swiper-preview .slider-swiper {
        height: 500px;
    }
    .slider-swiper__title {
        font-size: 50px;
        margin-bottom: 20px;
    }
}
@media (max-width: 768.98px) {
    .preview__bg {
        height: 216px;
        background-size: 100% 100%;
    }

    .preview__info::after {
        height: 24px;
        margin-top: 22px;
    }

    .preview__container {
        padding-bottom: 27px;
    }

    .preview__info {
        margin: -59px auto 0;
    }

    .preview__position {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        padding-top: 4px;
    }

    .preview__name {
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
    }

    .preview__img {
        width: 104px;
        height: 104px;
    }

  
}

@media (max-width: 575.98px) {
    .swiper-button-next,
    .swiper-button-prev {
        height: 100% !important;
        top: 6% !important;
        background-size: contain !important;
        background-position-y: 55% !important;
        margin: auto !important;
    }
    
    .swiper-preview {
        .slider-swiper {
            height: auto;
            min-height: 352px;
            padding: 33px 0 63px;
            background-position-x: 10% !important ;
        }
    }
    .slider-swiper__box {
        padding: 0 15px;
    }
    .slider-swiper__title {
        font-size: 32px;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -1px;
    }
    .slider-swiper__desc {
        width: 70%;
        color: #000;
        font-size: 14px;
        margin-bottom: 67px;
        line-height: 20px;
    }
    .button-on-slider {
        padding: 15px 30px;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 0;
        // transform: scale(0.8);
        // display: block;
        // margin-right: auto;
        // width: max-content;
    }
    .preview__bg {
        height: 106px;
    }

    .preview__img {
        width: 64px;
        height: 64px;
    }

    .preview__info {
        margin: -44px auto 0;
    }

    .preview__info::after {
        margin-top: 8px;
    }

    .preview__position {
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        padding-top: 0;
    }

    .preview__name {
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
    }
}
@media (max-width: 500.98px){

}