.contacts {
  &.wrapper {
    padding-right: 0;
  }
  .google-map {
    width: 100%;
    height: 100vh;
  }
  &__container {
    max-width: 1616px;
    width: 100%;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__info {
    max-width: 416px;
    width: 100%;
    margin-right: 15px;
    position: relative;
    top: -13px;
  }

  &__desc {
    margin-bottom: 37px;

    a {
      font-weight: 400;
      font-size: 17px;
      line-height: 27px;
      color: #191919;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #a1a1a1;
      display: block;
      margin-bottom: 10px;
    }
  }

  &__map {
    max-width: 1168px;
    width: 100%;
    height: 531px;
    background: center/cover no-repeat url(../../images/Map.png);
  }
}

@media (max-width: 991.98px) {
  .contacts__map {
    height: 435px;
    max-width: 429px;
  }

  .contacts__info {
    max-width: 262px;
    margin-right: 21px;
    top: 15px;
    margin-left: auto;
  }
}

@media (max-width: 575.98px) {
  .contacts__map {
    max-width: 100%;
    height: 166px;
  }

  .contacts.wrapper {
    padding-left: 0;
  }

  .contacts__desc span {
    margin-bottom: 8px;
  }

  .contacts__desc {
    margin-bottom: 17px;
  }

  .contacts__info {
    padding: 0 16px;
    top: 0;
    max-width: 100%;
    margin: 56px 0 38px;
  }

  .contacts__container {
    flex-direction: column-reverse;
  }
}
