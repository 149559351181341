.blog-page {
  // &.he {
  //   text-align: right;
  //   .blog-page__container {
  //     flex-direction: row-reverse;
  //   }
  // }

  &__bg {
    background-image: url('../../../images/blog-img.png');
    width: 100%;
    height: 432px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 140px;
  }

  &__container {
    display: flex;
    margin: 0 -16px;
    padding-bottom: 140px;
  }

  &__info {
    margin: 0 16px;

    span {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
      color: #333333;
      mix-blend-mode: normal;
      opacity: 0.5;
    }

    font-weight: 400;
    font-size: 34px;
    line-height: 50px;
    color: #323232;

  }

  &__desc {
    margin: 0 16px;
    max-width: 864px;
    width: 100%;

    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 160%;
      color: #4F4F4F;
    }
  }
}

@media (max-width: 768.98px) {
  .blog-page__container {
    margin: 0 auto;
    max-width: 656px;
    padding-bottom: 88px;
  }

  .blog-page__desc {
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 160%
    }

    margin: 0 10px;
  }

  .blog-page__info {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0 10px;
  }

  .blog-page__info span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .blog-page__bg {
    height: 216px;
    margin-bottom: 88px;
  }
}

@media (max-width: 575.98px) {
  .blog-page__bg {
    height: 106px;
    margin-bottom: 56px;
  }

  .blog-page__container {
    max-width: 100%;
    margin: 0 -10px;
    display: block;
  }

  .blog-page__info {
    margin-bottom: 32px;
  }

  .blog-page__desc {
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
    }

  }

  .blog-page__container {
    padding-bottom: 52px;
  }

}