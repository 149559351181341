html .disabled-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -999999;
  width: 100%;
  background-color: #ffffff !important;
  opacity: 0;

  &.active {
    
    opacity: 1;
    z-index: 99;
  }
  &__container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    justify-content: space-between;
  }
  &__name {
    text-align: center;
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    color: #333333;
    margin-bottom: 8px;
  }
  &__fonts {
    display: flex;
    justify-content: center;
    margin: 0 -4px;
  }
  &__button {
    margin: 0 4px;
    width: 42px;
    height: 42px;
    &.active {
      // background-color:  rgba(0, 0, 0, 1)
    }
    &_decrease {
      background: no-repeat url(../../images/font-decrease.svg) !important;

    }
    &_increase {
      background: no-repeat url(../../images/font-increase.svg) !important;
    }
  }
  &__color {
    margin: 0 4px;
    width: 42px;
    height: 42px;
    border: 1px solid #999999;
    border-radius: 4.6px;
    &_white {
      background: #ffffff;
    }
    &_black {
      background: #121212;
    }
    &_blue {
      background: #9dd1ff;
    }
    &_yellow {
      background: #f7f3d6;
    }
    &_yellow {
      background: #f3d4d6;
    }
  }
  &__image {
    margin: 0 4px;
    width: 42px;
    height: 42px;
    border-radius: 4.6px;
    &_show {
      background: no-repeat url(../../images/image-show.svg) !important;
    }
    &_hidden {
      background: no-repeat url(../../images/img-hidden.svg) !important;
    }
    &_inverse {
      background: no-repeat url(../../images/img-inverse.svg) !important;
    }
    &_reset {
      background: no-repeat url(../../images/reset.svg) !important;
    }
  }
}
@media (max-width: 1399px) {
  html .disabled-menu.active {
    z-index: 99999;
    top: 0;
    left: 0;
    // height: auto;
    height: 10%;
    position: fixed;
  }
}
@media (max-width: 1199px) {
  html .disabled-menu {
    bottom: -70%;
  }
}
@media (max-width: 768px) {
}
@media (max-width: 575px) {
  html .disabled-menu__container {
    flex-wrap: wrap;
    // justify-content: center;
  }
  html .disabled-menu.active {
    height: max-content;
  }
  .disabled-menu__box {
    margin-bottom: 15px;
  }
}
