.mobile-menu {
  display: none;

  &.header {

    .languages__btn_second {
      &.active {
        opacity: 1;
      }

      top: -3px;
      position: relative;
    }

    .languages {
      margin: 0 5px;
      display: flex;
      align-items: center;
      width: 60px;
      justify-content: space-between;
    }

    .languages__btn_main {
      &.active{
        opacity: 0.5;
      }
    }

    .languages__btn_main::after {
      display: none;
    }

    .languages__box {
      display: block;
      position: relative;
      bottom: 0;
    }
  }

  &.active {
    display: block;
  }

  &.header .social {
    display: flex;
    margin: 36px auto 15px;
  }

  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--main-color);
  z-index: 9;
  width: 100%;
  padding: 11px 16px;

  .header__nav {
    display: block;
  }

  .header__button {
    margin-bottom: 16px;
  }

  .header__row {
    margin: 0 auto;
  }


  .header__button,
  .header__row {
    display: block;
  }

  .header__link {
    display: block;
    width: max-content;
  }

  .header__logo {
    margin-right: auto;
    left: 0;
    top: 0;
    max-width: 170px;
  }

}