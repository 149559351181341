.problem-solving__title {
    font-weight: 700;
    font-size: 48px;
    line-height: 100%;
    align-items: center;
    text-align: center;
    color: #232323;
    margin-bottom: 40px;
}

.problem-solving__desc {
    max-width: 703px;
    width: 100%;
    margin: 0 auto 120px;
    text-align: center;
    color: #232323;
}

.problem-solving__container {
    padding: 180px 0 140px;
}

.problem-solving__button {
    max-width: 280px;
    display: block;
    margin: 54px auto 0;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    // background: url(../../images/img1.png);
}

.problem-solving__gallery {
    display: flex;
    max-width: 1920px;
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;
    align-items: stretch;
}

.problem-solving__item_first {
    width: 49%;
    overflow: hidden;

    margin-bottom: 32px;

    img {
        object-fit: cover;
        height: 100%;
    }

    // margin: 0 16px;/
}

.problem-solving__row {
    .problem-solving__item {
        max-width: 48%;
        height: 456px;
        width: 100%;
        margin: 0 0px 32px;
        overflow: hidden;
        // &:nth-child(1),
        // &:nth-child(2) {

        img {
            object-fit: cover;
            height: 100%;
        }
        // }
    }

    width: 49%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
@media (max-width: 1199.98px) {
    .problem-solving__row .problem-solving__item {
        height: 180px;
        // max-width: 180px;
    }
}
@media (max-width: 768.98px) {
    .problem-solving__title {
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        margin-bottom: 28px;
    }

    .problem-solving__desc {
        margin-bottom: 52px;
        width: 70%;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .problem-solving__container {
        padding: 85px 0 86px;
    }

    .problem-solving__row .problem-solving__item {
        &:nth-child(1),
        &:nth-child(2) {
            margin: 0 0 16px;
        }

        // height: auto;
        margin: 0 0 0px;
    }

    .problem-solving__item_first {
        margin-bottom: 0px;
    }
}

@media (max-width: 575.98px) {
    .problem-solving__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        margin-bottom: 32px;
    }

    .problem-solving__container {
        padding: 58px 0 54px;
    }

    .problem-solving__desc {
        text-align: left;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
        width: 95%;
        margin: 0 0 30px;
    }
    .problem-solving__row .problem-solving__item {
        height: 82px;
    }
    .problem-solving__row .problem-solving__item {
        &:nth-child(1),
        &:nth-child(2) {
            margin: 0 0 8px;
        }
    }
}
