.about {
    &__flex {
        display: flex;
        justify-content: space-between;
        padding: 250px 0 142px;
        max-width: 1308px;
        width: 100%;
        margin: 0 auto;
    }
    &__img {
        max-width: 542px;
        width: 100%;
        height: 638px;
    }
    &__bg {
        background: #f8f8f8;
        // padding: 220pxbackground: #D3E1E1; 0;
        width: 100%;
        height: 100%;
        // background: center/cover no-repeat url(../../images/about-bg.png);
    }

    &__info {
        max-width: 528px;
        width: 100%;
        // margin: 0 auto;
        padding: 30px 10px 30px;
        // height: 574px;
        // background-color: var(--main-color);
        overflow-y: hidden;
    }
    &__box {
        max-height: 250px;
        overflow-y: scroll;
    }

    &__title {
        font-family: "Roboto", sans-serif;
        font-size: 36px;
        font-family: Roboto;
        font-weight: 700;
        line-height: 150%;
        color: #c8952a;
        margin-bottom: 40px;
        span {
            color: #707070;
            font-size: 16px;
            display: block;
            line-height: 22px;
        }
    }

    &__text {
        // height: 78%;
        // overflow-y: scroll;
    }

    &__desc {
        padding-bottom: 28px;
        font-weight: 500;
        font-size: 21px;
        line-height: 154%;
        p {
            // text-align: center !important;
        }
    }

    &__scroll {
        width: 40px;
        height: 40px;
        margin: 10px auto 0;
        background: no-repeat url(../../images/scroll.svg);
    }

    &__additional {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 36px;
        line-height: 150%;
        max-width: 657px;
        width: 100%;
        margin: 0 auto;
        padding: 140px 0;
        text-align: center;

        &::after,
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 10px;
            background: center no-repeat url(../../images/Zigzag.svg);
        }

        &::after {
            margin-top: 32px;
        }

        &::before {
            margin-bottom: 32px;
        }

        span {
            font-weight: 700;
        }
    }

    &__items {
        background: var(--light-color);
    }

    &__row {
        padding: 140px 0;
        display: flex;
        justify-content: space-between;
        // margin: 0 -12px;
        width: 100%;
    }

    &__item {
        &:last-of-type {
            margin-right: 0;
        }

        margin-right: 10px;
        max-width: 304px;
        width: 100%;
        // border: 2px solid var(--accent-color);
        align-items: stretch;
        border-radius: 3px;
        // padding: 48px 32px 61px;
    }

    &__icon {
        width: 100%;
        height: 300px;
        margin: 0 auto 51px;
        background-image: url(../../images/about-icon.svg);
        background-repeat: no-repeat;

        &_account {
            background-position-x: 33%;
        }

        &_notice {
            background-position-x: 66%;
        }

        &_certificate {
            background-position-x: 99%;
        }
    }

    &__name {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        margin: 0 0 16px;
    }

    &__desc-item {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #a1a1a1;
    }
}
@media (max-width: 1399.98px) {
    .about__flex {
        padding: 250px 16px 149px;
    }
}
@media (max-width: 991.98px) {
    .about__info {
        padding: 0;
        margin: 0 auto;
    }
    .about__img {
        order: 1;
        margin: 0 auto 20px;
        max-width: 400px;
        height: 400px;
    }

    .about__flex {
        // justify-content: center;
        // flex-wrap: wrap;
        // flex-direction: column-reverse;
        padding: 50px 16px;
    }
    .about__row {
        flex-wrap: wrap;
        // margin: 0 -10px;
    }
    .about__title {
        text-align: left;
    }
    .about__item {
        margin-bottom: 48px;
    }
    .about__desc {
        padding: 0;
        margin-right: 22px;
    }
    .about__desc p {
        // text-align: center !important;
    }

    .about__item:last-of-type {
        margin-right: 10px;
    }
}

@media (max-width: 768.98px) {
    .about__row {
        padding: 86px 35px 44px;
    }

    .about__bg {
        // padding: 48px 0 56px;
    }

    .about__info {
        padding: 0 16px;
        max-width: 430px;
        // padding: 56px 26px 66px;
        // height: 320px;
    }

    .about__title {
        span {
            margin-top: 8px;
            font-size: 12px;
            line-height: 18px;
        }
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 8px;
        line-height: 22px
    }

    .about__desc {
        *{
            line-height: 18px !important;
        }
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .about__additional {
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        width: 48%;
        margin: 0 auto;
        padding: 84px 0 86px;

        &:after,
        &::before {
            width: 71px;
            background-size: contain;
            margin: 0 auto;
            height: 14px;
        }

        &:after {
            margin-top: 13px;
        }

        &::before {
            margin-bottom: 12px;
        }
    }
}

@media (max-width: 740.98px) {
    .about__row {
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding: 57px 6px 64px;
    }
    .about__flex {
        // justify-content: center;
        // flex-wrap: wrap;
        // flex-direction: column-reverse;
        // padding: 25px 16px;
    }
    .about__name {
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        margin-bottom: 8px;
    }

    .about__icon {
        // width: 320px;
        background-size: cover;
        height: 256px;
        margin-bottom: 21px;
    }

    .about__item {
        margin-right: 16px;
    }

    .about__items .wrapper {
        padding-right: 0;
    }

    .about__desc-item {
        font-size: 10px;
        line-height: 140%;
    }

    .about__item {
        padding: 40px 10px 48px;
        min-width: 256px;
        width: 256px;
        margin-bottom: 0;
    }
}

@media (max-width: 575.98px) {
    .about__img {
        width: 100%;
        max-width: 40%;
        position: relative;
        // top: 72px;   
        height: 161px;
        background-position: top !important;
    }
    .about__additional {
        width: 100%;
        padding: 37px 0 30px;
    }

    .about__desc {
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
    }
    .about__img {
        // max-width: 200px;
        // height: 200px;
    }
    .about__info {
        max-width: 80%;
        width: 100%;
        padding: 0px;
    }

    .about__bg {
        // padding: 48px 0 66px;
    }
}
