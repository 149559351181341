.reviews {
  position: relative;

  &__container {
    position: relative;
    padding: 148px 0px 60px;
  }

  .swiper-wrapper {
    justify-content: space-between;
  }

  &__box {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 515px;
    width: 100%;
    margin: 0 auto;
  }

  &__img {
    // background: center/cover no-repeat url(../../images/reviews-photo-1.png);
    max-width: 300px;
    width: 100%;
    height: 300px;
    // border-radius: 50%;
    margin: 0 auto 24px;
    &_default{
    background: center/cover no-repeat url(../../images/default.jpg);
    width: 100%;
    height: 300px;

    }
  }

  &__name {
    font-weight: 500;
    font-size: 24px;
    line-height: 50px;
    color: #191919;
    margin-bottom: 14px;
  }

  &__desc {
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
  }

  .swiper {
    position: relative;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: #e9e9e9;
    opacity: 1;
  }

  .swiper-pagination {
    display: none;
    position: relative;
    min-width: 98px;
    max-width: 98px;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 129px auto 0;
    justify-content: space-between;
  }

  .swiper-pagination-bullet-active {
    background: #a1a1a1;
    transform: scale(1.1);
  }

  .button {
    // transform: ;
    padding: 0;
    width: 40px;
    height: 40px;
    background-image: url(../../images/arrow-Right.svg);
    background-size: cover;
    // background-color: #d3e1e1;
  }

  .button.button-right {
    transform: rotate(-180deg);
  }
}

.swiper-button {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 87%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
}

@media (max-width: 1199.98px) {.reviews .button {

  background-color: #d3e1e1;
}
  .swiper-button {
    padding: 0 5px;
    z-index: 9;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 768.98px) {
  .reviews .swiper-pagination {
    display: none !important;
  }
  .reviews__box {
    margin: 0 auto;
  }

  .reviews__container {
    padding: 160px 0px 85px;
  }

  .reviews .swiper-pagination {
    margin-top: 75px;
  }
  .reviews__img {
    width: 300px;
    // background-size: contain !important;
    height: 300px;

    border-radius: 0;
  }
}

@media (max-width: 575.98px) {
  .reviews.wrapper {
    padding: 0px 0px;
  }
  .swiper-button {
    z-index: 9;
    left: 0;
    height: 10%;
    width: 100%;
    top: auto;
    bottom: 2px;
  }
 .reviews .button{
    background-color: transparent;
  }
  .reviews__container {
    position: inherit;
  }
  .reviews__img {
    // width: 100%;
    height: 300px;
    border-radius: 0;
  }
  .reviews .swiper-pagination {
    margin-top: 107px;
  }

  .reviews__container {
    padding: 104px 0px 49px;
  }

  .reviews__desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }

  .reviews__name {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    margin-bottom: 20px;
  }
  .reviews .swiper-button {
    padding: 0;
  }
  .reviews .button {
    background-color: transparent;
  }
}
